import './App.css';
import logo from './img/deisi-logo.svg';
import image1 from './img/img1.png';
import image2 from './img/img2.png';
import image3 from './img/img3.png';
import atelier from './img/atelier.svg';
import factory from './img/factory.svg';
import work from './img/work.svg';
import footlogo from './img/foot-logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Логотип" />
      </header>
      <div className="content">
        <div className="box" style={{ backgroundImage: `url(${image1})` }}>
          <div className="overlay">
            <img src={atelier} className="img-logo" alt="Логотип" />
            <a href="https://wa.me/+79296875550" target="_blank" rel="noopener noreferrer">
              <button className="details-button">СВЯЗАТЬСЯ</button>
            </a>
          </div>
        </div>
        <div className="box" style={{ backgroundImage: `url(${image2})` }}>
          <div className="overlay">
            <img src={factory} className="img-logo" alt="Логотип" />
            <a href="https://wa.me/+79296875550" target="_blank" rel="noopener noreferrer">
              <button className="details-button">СВЯЗАТЬСЯ</button>
            </a>
          </div>
        </div>
        <div className="box" style={{ backgroundImage: `url(${image3})` }}>
          <div className="overlay">
            <img src={work} className="img-logo" alt="Логотип" />
            <a href="https://wa.me/+79296875550" target="_blank" rel="noopener noreferrer">
              <button className="details-button">СВЯЗАТЬСЯ</button>
            </a>
          </div>
        </div>
      </div>
      <footer className="App-footer">
        <div className="footer-content">
          <div className="contact-info">
            <p className="contact-heading">Контакты</p>
            <p className="contact-number">8 (800) 222-44-55</p>
            <p className="contact-address">Адрес</p>
            <p className="contact-address-detail">Спиридоньевский пер., 1/24, Москва</p>
          </div>
          <div className="logo-footer">
            <img src={footlogo} className="App-logo" alt="Логотип" />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;